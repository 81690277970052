<template>
  <div
    class="page-common-body product-detail-page-container"
    id="productDetailWrapper"
  >
    <div class="product-top-info-container">
      <div class="info__main-wrapper">
        <div class="info__main-body">
          <!-- 基础信息 -->
          <div class="product__info-container" v-if="goods">
            <div class="info-container__wrapper">
              <div class="info__name">
                <p>{{ goods.category.name.cn }}-{{ goods.name }}</p>
                <p>¥ {{ goods.price }}</p>
                <div
                  class="name__collect"
                  :class="{
                    isCollect: isCollected
                  }"
                  :data-gid="goods.uuid"
                  :data-iscollect="isCollected ? 1 : 0"
                  @click="onCollect"
                ></div>
              </div>
              <div class="info__summary" v-if="goods.summary">
                <p v-if="goods.summary.cn">
                  {{ goods.summary.cn }}
                </p>
                <p v-if="goods.summary.en">
                  {{ goods.summary.en }}
                </p>
              </div>
              <!-- 扩展选项 -->
              <el-form
                v-if="goods"
                class="extend-form-container"
                ref="extendForm"
                :rules="extendsFormRules"
                :model="form"
              >
                <el-form-item
                  :label="optionTitle + ':'"
                  v-if="extendsOptions.length > 0"
                  prop="extendOption"
                >
                  <el-select
                    v-model="form.extendOption"
                    :placeholder="'请选择' + optionTitle"
                  >
                    <el-option
                      v-for="option in extendsOptions"
                      :key="option.id"
                      :label="option.extend_key"
                      :value="option.extend_key"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="数量:" prop="counts">
                  <el-input-number
                    v-model="form.counts"
                    :controls-position="$bowser.mobile ? '' : 'right'"
                    @change="handleChange"
                    :min="1"
                    :max="10"
                  ></el-input-number>
                </el-form-item>
                <div class="product-hint">
                  <p>有货</p>
                  <p>预计发货后2-4个工作日送达</p>
                </div>
                <el-form-item v-if="!$bowser.mobile">
                  <el-button type="primary" @click="addCart"
                    >加入购物车</el-button
                  >
                </el-form-item>
              </el-form>
              <!-- 分享 -->
              <share-list
                class="product-share-list"
                v-if="!$bowser.mobile"
              ></share-list>
            </div>
          </div>
          <!-- 产品轮播图 -->
          <div
            class="product__siwper-container"
            v-if="goods && goods.slides.length > 0"
          >
            <div class="swiper-container">
              <swiper ref="detailSwiper" :options="swiperOptions">
                <swiper-slide
                  v-for="(item, index) in goods.slides"
                  :key="index"
                >
                  <img :src="item | parseImage" alt="" />
                </swiper-slide>
              </swiper>
              <div class="detail-swiper-pagination"></div>
            </div>
          </div>
        </div>
        <!-- 产品详情 -->
        <template v-if="goods && goods.detail">
          <div class="info__detail-container" v-html="goods.detail"></div>
        </template>
      </div>
    </div>
    <!-- 其他参数 -->
    <template v-if="$bowser.mobile && extendsDesc.length > 0">
      <div class="product-extendsDesc4mobile">
        <div class="bar__inner">
          <div
            class="bar__item"
            v-for="item in extendsDesc"
            :key="item.id"
            @click="onActive"
          >
            <div class="item__wrapper">
              <div class="item__title">{{ item.title }}</div>
              <div
                class="item__content"
                :class="{
                  custom: item.template_id === 0
                }"
              >
                <template v-if="item.template_id === 0">
                  <p
                    v-for="(text, index) in item.content.split('\r\n')"
                    :key="index"
                  >
                    {{ text }}
                  </p>
                </template>
                <template v-else>
                  <product-extend-desc
                    :templateId="item.template_id"
                  ></product-extend-desc>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div
      class="product-bottom-recommend-container"
      id="productDetailRecommend"
      v-if="recommends.length > 0"
    >
      <div class="recommend-lists-container">
        <div class="list__wrapper">
          <product-list
            class="recommend__list-container"
            :lists="recommends"
          ></product-list>
        </div>
      </div>
      <div class="recommend__title">
        <p>Recommend</p>
        <p>热卖推荐</p>
      </div>
    </div>
    <div
      class="product-fixed-side-bar-container"
      id="productDetailFixedSideBar"
    >
      <div class="bar__inner" v-if="extendsDesc.length > 0">
        <div
          class="bar__item"
          v-for="item in extendsDesc"
          :key="item.id"
          @click="onActive"
        >
          <div class="item__wrapper">
            <div class="item__title">{{ item.title }}</div>
            <div
              class="item__content"
              :class="{
                custom: item.template_id === 0
              }"
            >
              <template v-if="item.template_id === 0">
                <p
                  v-for="(text, index) in item.content.split('\r\n')"
                  :key="index"
                >
                  {{ text }}
                </p>
              </template>
              <template v-else>
                <product-extend-desc
                  :templateId="item.template_id"
                ></product-extend-desc>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div
        class="bar__inner"
        v-if="goods && Number(goods.is_custom_made) === 2"
      >
        <p class="custom-made-bt" @click="createCustomForm">联系定制</p>
      </div>
    </div>
    <!-- 手机端底部 -->
    <template v-if="$bowser.mobile">
      <div class="product-fixed-bottom">
        <div
          class="bottom__item"
          v-if="goods && Number(goods.is_custom_made) === 2"
          @click="createCustomForm"
        >
          <p>customized</p>
          <p>联系定制</p>
        </div>
        <el-button class="bottom__item bottom__bt --green" @click="addCart"
          >加入购物车</el-button
        >
        <el-button
          class="bottom__item bottom__bt --black"
          @click="directPay"
          :loading="directPayRequesting"
          >立即购买</el-button
        >
      </div>
    </template>
    <template v-if="!$bowser.mobile && customFormShow && goods">
      <div class="fixed-custom-container">
        <div class="__bg"></div>
        <div class="bar__header">
          <div class="header__close" @click="onCustomFormClose"></div>
          <div class="header__title">个性定制</div>
        </div>
        <div class="custom-wrapper">
          <custom-form
            :gid="id"
            :good-name="goodName"
            :after-success="afterCustomFormSuccess"
          ></custom-form>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import shareList from "../common/_share-list";
import productList from "../common/_product-list";
import productExtendDesc from "../common/_product-extend-desc";
import customForm from "./_custom-form";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { mapActions, mapMutations } from "vuex";
import wxtool from "@/assets/utils/wx-tool.js";
export default {
  name: "page-product-detail",
  props: ["id"],
  components: {
    shareList,
    productList,
    Swiper,
    SwiperSlide,
    productExtendDesc,
    customForm
  },
  data() {
    return {
      form: {
        extendOption: "",
        counts: 1
      },
      goods: null,
      recommends: [],
      swiperOptions: {
        autoplay: true,
        loop: true,
        pagination: {
          el: ".detail-swiper-pagination",
          bulletClass: "detail-swiper__dot",
          bulletActiveClass: "detail-swiper__active"
        }
      },
      extendsDesc: [],
      extendsOptions: [],
      isCollected: false,
      directPayRequesting: false,
      customFormShow: false
    };
  },
  computed: {
    goodName() {
      if (this.goods) {
        return `${this.goods.category.name.cn}-${this.goods.name}`;
      } else {
        return "";
      }
    },
    extendsFormRules() {
      const rules = {
        counts: [
          {
            required: true,
            message: "请选择数量",
            trigger: "change"
          }
        ]
      };
      if (this.extendsOptions.length > 0) {
        rules.extendOption = [
          {
            required: true,
            message: `请选择${this.optionTitle}`,
            trigger: "change"
          }
        ];
      }
      return rules;
    },
    optionTitle() {
      if (this.extendsOptions.length > 0) {
        const info = this.$configs.optionType.find(
          item =>
            Number(item.type) === Number(this.extendsOptions["0"].extend_type)
        );
        return info.name;
      } else {
        return "";
      }
    }
  },
  watch: {
    $route: {
      handler: "init",
      immediate: true
    }
  },
  methods: {
    ...mapMutations("shop", [
      "CLEAR_DIRECTFORM",
      "SET_DIRECTFORM",
      "OPEN_CART"
    ]),
    ...mapMutations(["PAGE_LOADING", "PAGE_LOADED"]),
    ...mapActions("shop", ["cartAdd"]),
    handleChange(val) {
      console.log(val);
    },
    init() {
      const that = this;
      that.PAGE_LOADING();
      const url = `/api/shop/product/`;
      that.$request
        .get(url, {
          id: that.id
        })
        .then(res => {
          that.goods = res.data.goods;
          that.recommends = res.data.recommends;
          that.extendsDesc = res.data.extendsDesc;
          that.extendsOptions = res.data.extendsOptions;
          that.isCollected = res.data.isCollect;
          that.PAGE_LOADED();
          const wxTitle = `${that.goods.category.name.cn} - ${that.goods.name}`;
          const wxDes = that.goods.summary
            ? that.goods.summary.cn
            : "为各领域的软装设计需求提供量身定制的专业服务";
          const wxImg = process.env.VUE_APP_IMAGE_URL + that.goods.cover.normal;
          that.wxShare(wxTitle, wxDes, wxImg);
        });
    },
    onActive(e) {
      const target = e.currentTarget;
      const contentRect = target
        .querySelector(".item__content")
        .getBoundingClientRect();
      if (target.classList.contains("active")) {
        target.classList.remove("active");
        target.style.cssText = `height:${target.offsetHeight -
          contentRect.height}px`;
      } else {
        target.classList.add("active");
        target.style.cssText = `height:${target.offsetHeight +
          contentRect.height}px`;
      }
    },
    addCart() {
      const that = this;
      if (that.isLogin()) {
        // 添加到购物车
        that.$refs.extendForm.validate(valid => {
          if (valid) {
            const addCartData = that.getCartData();
            that.cartAdd(addCartData).then(() => {
              that.$message({
                message: "添加成功",
                type: "success"
              });
              if (!that.$bowser.mobile) {
                // pc 端打开购物车
                that.OPEN_CART();
              }
            });
          }
        });
      } else {
        // 去登录
      }
    },
    onCollect(e) {
      const dataset = e.currentTarget.dataset;
      const gid = dataset.gid;
      const isCollect = Number(dataset.iscollect);
      const that = this;
      const url = "/api/user/collect/";
      if (that.isLogin()) {
        if (isCollect === 1) {
          // 删除
          that
            .$confirm("是否删除该收藏", "提示", {
              confirmButtonText: "删除",
              cancelButtonText: "取消",
              type: "warning"
            })
            .then(() => {
              that.$request
                .del(`${url}?gid=${gid}`)
                .then(res => {
                  console.log(res);
                  that.isCollected = false;
                })
                .catch(err => {
                  console.log(err);
                });
            })
            .catch(() => {
              console.log("取消");
            });
        } else {
          // 添加
          that.$request
            .post(url, {
              gid
            })
            .then(res => {
              console.log(res);
              that.isCollected = true;
            })
            .catch(err => {
              console.log(err);
            });
        }
      } else {
        // 去登录
      }
    },
    getCartData() {
      const that = this;
      const addCartData = {
        gid: that.goods.uuid,
        counts: Number(that.form.counts),
        total_price: Number(that.form.counts) * Number(that.goods.price),
        extends: that.form.extendOption
          ? `${that.optionTitle}:${that.form.extendOption}`
          : "",
        goods: {
          cover: {
            normal: that.goods.cover.normal
          },
          name: that.goods.name,
          category: {
            name: {
              cn: that.goods.category.name.cn
            }
          },
          // 直接下单用
          price: Number(that.goods.price)
        }
      };
      return addCartData;
    },
    directPay() {
      const that = this;
      if (that.isLogin()) {
        // 添加到购物车
        that.$refs.extendForm.validate(valid => {
          if (valid) {
            that.directPayRequesting = true;
            const addCartData = that.getCartData();
            that.CLEAR_DIRECTFORM();
            that.SET_DIRECTFORM(addCartData);
            that.$router.push({
              path: `/order/create/0/?gid=${addCartData.gid}`
            });
          }
        });
      }
    },
    wxShare(title, desc, imgUrl) {
      const that = this;
      that.$nextTick(() => {
        const link = document.URL;
        wxtool.init().then(wx => {
          wx.updateAppMessageShareData({
            title,
            desc,
            link,
            imgUrl,
            success() {
              console.log("分享成功");
            }
          });
          wx.updateTimelineShareData({
            title,
            desc,
            link,
            imgUrl,
            success() {
              console.log("分享成功");
            }
          });
        });
      });
    },
    createCustomForm() {
      const that = this;
      const goodid = that.id;
      const category = that.goods.category.name.cn;
      const name = that.goods.name;
      if (that.$bowser.mobile) {
        // 跳转
        that.$router.push({
          path: `/custom?gid=${goodid}&category=${category}&name=${name}`
        });
      } else {
        // 本页弹开
        that.customFormShow = true;
      }
    },
    onCustomFormClose() {
      this.customFormShow = false;
    },
    afterCustomFormSuccess() {
      this.customFormShow = false;
    }
  }
};
</script>

<style></style>
