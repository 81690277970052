<template>
  <div class="share-container">
    <div class="share__title">分享:</div>
    <div class="share__bts">
      <div class="bt__item">
        <i class="icon icon-wechat"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "c-share-list"
};
</script>

<style></style>
