<template>
  <p v-if="templateId === sales">
    普通商品如有质量问题，请在签收后7日内联系客服。定制商品等不接受7天无理由退换货，如有质量问题，请在签收后24小时内联系客服。
  </p>
  <div v-else>
    <div class="c-title">{{ text[templateId].title }}</div>
    <div class="icons">
      <p v-for="(icon, index) in text[templateId].icons" :key="index">
        <img :src="icon" alt="" />
      </p>
    </div>
  </div>
</template>

<script>
const TEMPLATE_TRANSPOT = 1;
const TEMPLATE_PAY = 2;
const TEMPLATE_SALES_RETURN = 3;
export default {
  name: "c_product-extend-desc",
  props: {
    templateId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      transpot: TEMPLATE_TRANSPOT,
      pay: TEMPLATE_PAY,
      sales: TEMPLATE_SALES_RETURN,
      text: {
        [TEMPLATE_TRANSPOT]: {
          title: "支持顺丰快递，部分偏远地区支持EMS",
          icons: [
            require("@/assets/images/shop/icon-sf.png"),
            require("@/assets/images/shop/icon-ems.png")
          ]
        },
        [TEMPLATE_PAY]: {
          title: "支持支付宝支付（含花呗）、微信支付",
          icons: [
            require("@/assets/images/shop/icon-alipay.png"),
            require("@/assets/images/shop/icon-wechat-pay.png")
          ]
        }
      }
    };
  }
};
</script>

<style></style>
